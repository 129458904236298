<template>
   <div class="card mb-0">
      <div class="card-buttons-top">
         <div class="card-body">
            <div id="buttons" class="w-100 text-center pb-5">
               <div class="badge bg-theme cursor-pointer me-2" @click="abrir" v-if="tipo == 'Cursos'">
                  <i class="fal fa-folder font-11 me-1"></i> Abrir
               </div>
               <div class="badge bg-theme cursor-pointer me-2" @click="editar">
                  <i class="fal fa-pen font-11 me-1"></i> Editar
               </div>
               <div class="badge bg-dark cursor-pointer" @click="deletar">
                  <i class="far fa-trash font-11 me-1"></i> Excluir
               </div>
            </div>
            
				<h6 class="limitador-2 font-20">{{ pacote.nome }}</h6>
            <div class="row card-info-bottom align-items-center">
               <div class="col-6">
                  <h6 class="limitador-2 font-16"><small class="color-theme">R$</small> {{ parseFloat(pacote.valor).toFixed(2) }}</h6>
               </div>
               <div class="col-6 text-end" v-if="tipo == 'Pacotes'">
                  <i class="fas fa-percentage font-16 color-theme me-3" title="Em promoção" v-if="pacote.isPromocao"></i>
                  <i class="fas fa-eye-slash font-16 color-theme me-3" title="Oculto da loja" v-if="pacote.isDesabilitado"></i>
                  <i class="fas fa-star font-16 color-theme" title="Em destaque" v-if="pacote.isDestaque"></i>
               </div>
               <div class="col-6 text-end" v-else-if="tipo == 'Cursos'">
                  <span class="font-17"><i class="fas fa-barcode font-14 color-theme me-1"></i> {{ pacote.codigo }}</span>
               </div>
               <div class="col-12 mt-1">
                  <p class="limitador-3"><i class="fas fa-info-circle font-12 color-theme me-1"></i> {{ pacote.descricao != null ? pacote.descricao : 'Sem descrição' }}</p>
               </div>
               <div class="col-12 mt-2" v-if="tipo == 'Pacotes' || tipo == 'Scripts'">
                  <a :href="pacote.linkSvn" target="_blank" class="font-13 d-block limitador mb-1" v-if="pacote.linkSvn">
                     <i class="fas fa-turtle font-12 color-theme me-1"></i> {{ pacote.linkSvn }}
                  </a>
                  <a :href="pacote.linkDocumentacao" target="_blank" class="font-13 d-block limitador mb-1" v-if="pacote.linkDocumentacao">
                     <i class="fas fa-file-alt font-12 color-theme me-1"></i> {{ pacote.linkDocumentacao }}
                  </a>
                  <a :href="pacote.linkDriver" target="_blank" class="font-13 d-block limitador" v-if="pacote.linkDriver">
                     <i class="fab fa-google-drive font-12 color-theme me-1"></i> {{ pacote.linkDriver }}
                  </a>
               </div>
            </div>
         </div>
      </div>
      <img :src="pacote.nomeArquivo == null ? '' : pacote.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Pacote',
	props: ['pacote', 'tipo'],
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.pacote)
      },
      deletar : function () {
         this.$emit('deletar', this.pacote)
      },
      abrir : function () {
         this.$emit('abrir', this.pacote, this.index)
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   display: flex;
	flex-wrap: wrap;
   justify-content: center;
   align-items: end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: 0.3s;
}

.card-buttons-top:hover {
	background: rgba(0, 0, 0, 0.658);
}

.card-buttons-top:hover #buttons {
	display: block !important;
}

.card-buttons-top #buttons {
	display: none !important;
}

.card-info-bottom * {
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

.card-info-bottom a {
   color: #ddd;
   cursor: pointer;
}

.card-info-bottom a:hover {
   text-decoration: underline !important;
}

h6 {
   color: #ddd;
   margin-bottom: 10px !important;
   font-weight: 700 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

p {
	color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
	line-height: 1.4;
}

img {
	aspect-ratio: 3/4;
	object-fit: cover;
}

</style>