<template>
   <div class="row">
      <!-- pesquisa -->
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-lg-2 col-12 mb-3">
                     <label><i class="far fa-filter font-13 color-theme mr-1 cursor-pointer"></i> Filtro</label>
                     <v-select title="Desktop" :clearable="false" :options="['Pacotes','Cursos',  'Scripts']" v-model="pesquisa.tipo" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo">
                        <option v-for="(option, index) in ['Pacotes','Cursos', 'Scripts']" :key="index" :value="option">{{ option }}</option>
                     </select>
                  </div>
                  <div class="col-lg-7 col-12 mb-3 px-1">
                     <label><i class="far fa-search font-13 color-theme mr-1"></i> Buscar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" />
                  </div>
                  <div class="col-lg-1 col-6 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="search">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-lg-2 col-6 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="adicionar">
                        <i class="fa fa-plus font-13 mr-1"></i> Adicionar
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- lista de pacotes -->
      <div class="col-12" v-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Pacotes'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Pacotes'"
               @deletar="deletarPacote($event)" @editar="editarPacote($event)"/>
         </div>
      </div>

      <!-- lista de cursos -->
      <div class="col-12" v-else-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Cursos'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Cursos'"
               @abrir="abrirModulo($event)" @deletar="deletarModulo($event)" @editar="editarModulo($event)"/>
         </div>
      </div>

      <!-- lista de scripts -->
      <div class="col-12" v-else-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Scripts'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Scripts'"
               @editar="editarScript($event)" @deletar="deleteScript($event)"/>
         </div>
      </div>

      <!-- modalEditarPacote -->
      <div class="modal fade" id="modalEditarPacote" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalEditarPacoteLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEditarPacoteLabel" v-if="pesquisa.resultado != null && modalPacote.id != null">Editar pacote</h5>
                  <h5 class="modal-title" id="modalEditarPacoteLabel" v-else>Adicionar pacote</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="text-center mb-0">Informações do pacote</h6>
                                       <h6 class="mb-0">{{valorRecomendado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="fas fa-file font-13 color-theme me-1"></i> Título</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalPacote.nome"/>
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="fas fa-dollar-sign font-14 color-theme me-1"></i> Valor</label>
                                    <input type="text" class="form-control" v-mask="['#.##', '##.##', '###.##', '####.##']" v-model="modalPacote.valor"/>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label class="mb-1"><i class="fas fa-tag font-13 color-theme me-1"></i> Descrição</label>
                                    <textarea style="height: 200px;" class="form-control" maxlength="1000" v-model="modalPacote.descricao"></textarea>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-filter font-13 color-theme me-1 cursor-pointer"></i> Tipo</label>
                                    <v-select title="Desktop" :clearable="false" :options="['Cursos', 'Bases', 'Scripts', 'Favelas', 'Veiculos', 'Skin armas', 'Viaturas', 'Roupas', 'Roupas facção', 'Mapas', 'Free']" v-model="modalPacote.tipo"/>
                                    <select title="Mobile" class="form-control" v-model="modalPacote.tipo">
                                       <option v-for="(option, index) in ['Cursos', 'Bases', 'Scripts', 'Favelas', 'Veiculos', 'Skin armas', 'Viaturas', 'Roupas', 'Roupas facção', 'Mapas', 'Free']" :key="index" :value="option">{{ option }}</option>
                                    </select>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="fas fa-file font-13 color-theme me-1"></i> Link documentação</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalPacote.linkDocumentacao"/>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="flexCheckDefault" v-model="modalPacote.isPromocao">
                                       <label class="form-check-label cursor-pointer" for="flexCheckDefault"><i class="fas fa-percentage font-13 color-theme me-1"></i> Em promoção</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="flexCheckDefault2" v-model="modalPacote.isDesabilitado">
                                       <label class="form-check-label cursor-pointer" for="flexCheckDefault2"><i class="fas fa-eye font-13 color-theme me-1"></i> Ocultar da loja</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="flexCheckDefault3" v-model="modalPacote.isDestaque">
                                       <label class="form-check-label cursor-pointer" for="flexCheckDefault3"><i class="fas fa-star font-13 color-theme me-1"></i> Em destaque</label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Banner do pacote</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalPacote.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalPacote.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalPacote.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalPacote.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="pacoteFiles" />
                                 </div>
                                 <div class="col-12 px-1 mt-2" v-if="String(modalPacote.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded" :src="modalPacote.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Nenhum banner encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="mb-0">Cursos do pacote</h6>
                                       <h6 class="mb-0">{{modalPacote.modulos.length + ' cursos'}}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-if="modalPacote.modulos.length > 0">
                                    <div class="cards-5">
                                       <div class="imageContainer" @click="vinculo(modulo, modalPacote)" v-for="(modulo, index) in modalPacote.modulos" :key="index">
                                          <img :title="modulo.nome" class="rounded cursoVinc" :class="modulo.vinculo == true ? 'borderSelect': 'borderNotSelect'" :src="modulo.nomeArquivo == null ? '' : modulo.nomeArquivo" @error="imageError">
                                          <i class="far fa-link text-success" v-if="modulo.vinculo == true"></i>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Nenhum resultado encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="text-center mb-0">Scripts do pacote</h6>
                                       <h6 class="mb-0">{{modalPacote.produtos.length + ' scripts'}}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-if="modalPacote.produtos.length > 0">
                                    <div class="cards-5">
                                       <div class="imageContainer" @click="vinculo(produto, modalPacote)" v-for="(produto, index) in modalPacote.produtos" :key="index">
                                          <img :title="produto.nome" class="rounded cursoVinc" :class="produto.vinculo == true ? 'borderSelect': 'borderNotSelect'" :src="produto.nomeArquivo == null ? '' : produto.nomeArquivo" @error="imageError">
                                          <i class="far fa-link text-success" v-if="produto.vinculo == true"></i>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Nenhum resultado encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePacote">Salvar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalEditarCurso -->
      <div class="modal fade" id="modalEditarCurso" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalEditarCursoLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEditarCursoLabel" v-if="pesquisa.resultado != null && modalModulo.id != null">Editar curso</h5>
                  <h5 class="modal-title" id="modalEditarCursoLabel" v-else>Adicionar curso</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do curso</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="fas fa-file font-13 color-theme mr-1"></i> Título</label>
                                    <input type="text" class="form-control rounded" maxlength="200" v-model="modalModulo.nome"/>
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="fas fa-dollar-sign font-14 color-theme mr-1"></i> Valor</label>
                                    <input type="text" class="form-control rounded" v-mask="['#.##', '##.##', '###.##', '####.##']" maxlength="7" v-model="modalModulo.valor"/>
                                 </div>
                                 <div class="col-12 mb-3 px-1">
                                    <label><i class="fas fa-hashtag font-13 color-theme mr-1"></i> Código</label>
                                    <input type="text" class="form-control rounded" maxlength="10" v-model="modalModulo.codigo"/>
                                 </div>
                                 <div class="col-12 px-1">
                                    <label class="mb-1"><i class="fas fa-tag font-13 color-theme mr-1"></i> Descrição</label>
                                    <textarea style="height: 200px;" class="form-control rounded" maxlength="1000" v-model="modalModulo.descricao"></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Banner do curso</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalModulo.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalModulo.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalModulo.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalModulo.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="cursoFiles" />
                                 </div>
                                 <div class="col-12 mt-2" v-if="String(modalModulo.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded" :src="modalModulo.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Nenhum banner encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveModulo">Salvar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalEditarScript -->
      <div class="modal fade" id="modalEditarScript" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalEditarScriptLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEditarScriptLabel" v-if="pesquisa.resultado != null && modalScript.id != null">Editar Script</h5>
                  <h5 class="modal-title" id="modalEditarScriptLabel" v-else>Adicionar Script</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do Script</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="fas fa-file font-13 color-theme me-1"></i> Título</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.nome"/>
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="fas fa-dollar-sign font-14 color-theme me-1"></i> Valor</label>
                                    <input type="text" class="form-control" v-mask="['#.##', '##.##', '###.##', '####.##']" maxlength="7" v-model="modalScript.valor"/>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label class="mb-1"><i class="fas fa-tag font-13 color-theme me-1"></i> Descrição</label>
                                    <textarea style="height: 200px;" class="form-control" maxlength="1000" v-model="modalScript.descricao"></textarea>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="fab fa-google-drive font-13 color-theme me-1"></i> Link do driver</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkDriver"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="fas fa-file-alt font-13 color-theme me-1"></i> Link da documentação</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkDocumentacao"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="fas fa-user font-13 color-theme me-1"></i> Login do SVN</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.loginSvn"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1 position-relative">
                                    <label><i class="fas fa-lock font-13 color-theme me-1"></i> Senha do SVN</label>
                                    <input :type="showPassword ? 'text' : 'password'" class="form-control" maxlength="200" v-model="modalScript.senhaSvn"/>
                                    <span @click="showPassword = !showPassword;" class="toggle-password">
                                       <i v-if="showPassword" class="fas fa-eye"></i>
                                       <i v-else class="fas fa-eye-slash"></i>
                                    </span>  
                                 </div>
                                 <div class="col-12 mb-3 px-1">
                                    <label><i class="fas fa-turtle font-13 color-theme me-1"></i> Link do SVN</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkSvn"/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Banner do Script</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalScript.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalScript.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalScript.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalScript.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="scriptFile" />
                                 </div>
                                 <div class="col-12 px-1 mt-2" v-if="String(modalScript.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded border border-secondary" :src="modalScript.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Nenhum banner encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Video do script</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="fas fa-file font-13 color-theme mr-1"></i> Link</label>
                                    <input type="text" class="form-control" placeholder="Src do Iframe" maxlength="500" v-model="modalScript.link"/>
                                 </div>
                                 <div class="col-12 px-1" v-if="String(modalScript.link).startsWith('https://www.youtube.com/embed/') || String(modalScript.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                                    <div class="ratio ratio-16x9">
                                       <iframe class="rounded" width="100%" height="400" :src="modalScript.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>Video não encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Imagens</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1 mb-2" v-show="modalScript.listFotos == null || modalScript.listFotos.length < 3">
                                    <input type="file" class="form-control" multiple accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="imagemFile" />
                                 </div>
                                 <div class="col-12 px-1">
                                    <div class="cards-5">
                                       <div class="imageContainer" v-for="(foto, index) in modalScript.listFotos" :key="index">
                                          <img class="rounded cursoVinc" :src="foto.nomeArquivo == null ? '' : foto.nomeArquivo" @error="imageError">
                                          <i class="far fa-trash text-danger cursor-pointer" @click="removeImage(index)"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveScript">Salvar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import pacote from '@/components/admin/gerenciar/Pacote.vue'

export default {
	name: 'Gerenciar',
	data: function() {
		return {
         valorRecomendado: 0,
         pesquisa: {'nome': '', 'tipo': 'Pacotes', 'resultado': []},
			modalPacote: {
            'id': null, 'nome': null, 'descricao': null, 'valor': '0.00', 'nomeArquivo': null, 'tipo': null, 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false, 
            'tags': null, 'qtdVendidos': 0, 'data': null, 'linkDocumentacao': null, 'urlImage': null, 'jaPossuiPacote': false, 'qtdModulos': 0, 'qtdProdutos': 0, 
            'totalViews': 0, 'modulos': [], 'produtos': []
         },
			modalModulo: {
            'id': null, 'nome': null, 'codigo': null, 'pontuacao': 0, 'qtdVotos': 0, 'descricao': null, 'nomeArquivo': null, 'valor': '0.00', 'urlImage': null, 
            'media': 5, 'vinculo': false, 'blocos': []
         },
			modalScript: {
            'link': null, 'linkDriver': null, 'valor': '0.00', 'nome': null, 'senhaSvn': null, 'descricao': null, 'loginSvn': null, 'listFotos': [], 
            'vinculo': false, 'id': null, 'linkSvn': null, 'linkDocumentacao': null, 'urlImage': null
         },
         numeroResultados: 0,
         showPassword: false
		}
	},
   watch: {
      'pesquisa.tipo': function(novoValor) {
         localStorage.setItem('ultimaOpcaoSelecionada', novoValor);
         this.search()
      }
   },
   created() {
      const tipoSalvo = localStorage.getItem('ultimaOpcaoSelecionada');
      if (tipoSalvo) {
         this.pesquisa.tipo = tipoSalvo;
      } else {
         this.pesquisa.tipo = 'Pacotes';
      }
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		}),
	},
	components: {
      pacote
   },
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      search : function () {
         if(this.pesquisa.tipo == 'Pacotes') {
            this.searchPacotes(0)
            this.searchAllModulos()
            this.searchAllProdutos()
         } else if (this.pesquisa.tipo == 'Cursos') {
            this.searchModulo()
         } else if (this.pesquisa.tipo == 'Scripts') {
            this.searchScripts()
         }
      },
      adicionar : function () {
         if(this.pesquisa.tipo == 'Pacotes') {
            this.adicionarPacote()
         } else if (this.pesquisa.tipo == 'Cursos') {
            this.adicionarDocumento()
         } else if (this.pesquisa.tipo == 'Scripts') {
            this.adicionarScript()
         }
      },
      // pacotes
		searchPacotes : function () {
         let ref = this;

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchPacotes',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
            this.pesquisa.resultado = response.data.resultado

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      searchAllModulos : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.modalPacote.modulos = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllModulos'
         }).then(response => {
				ref.modalPacote.modulos = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      searchAllProdutos : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.modalPacote.produtos = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllProdutos'
         }).then(response => {
				ref.modalPacote.produtos = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		savePacote : function () {
         let ref = this;

         if (this.modalPacote.nome == null || this.modalPacote.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um título!'
            })

            return
         } else if (this.modalPacote.tipo == null || this.modalPacote.tipo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um tipo'
            })

            return
         } else if (this.modalPacote.valor == null || this.modalPacote.valor.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um valor'
            })

            return
         }

         let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalPacote));

         for (let file of this.$refs.pacoteFiles.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Tamanho excedido',
                  text: 'Arquivo excede 10MB'
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/savePacote',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(function () {
            ref.searchPacotes(0)
            ref.$toast.fire({
               icon: 'success',
               title: 'Pacote salvo!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarPacote').modal('hide')

            this.$refs.pacoteFiles.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		adicionarPacote : function () {
         this.searchAllModulos()
         this.searchAllProdutos()
         this.valorRecomendado = 0
         this.modalPacote = {
            'id': null, 'nome': null, 'descricao': null, 'valor': '0.00', 'nomeArquivo': null, 'tipo': null, 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false, 
            'tags': null, 'qtdVendidos': 0, 'data': null, 'linkDocumentacao': null, 'urlImage': null, 'jaPossuiPacote': false, 'qtdModulos': 0, 'qtdProdutos': 0, 
            'totalViews': 0, 'modulos': [], 'produtos': []
         }

         $('#modalEditarPacote').modal('show')
      },
		editarPacote : function (pacote) {
         let ref = this
         this.modalPacote = {
            'id': null, 'nome': null, 'descricao': null, 'valor': '0.00', 'nomeArquivo': null, 'tipo': null, 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false, 
            'tags': null, 'qtdVendidos': 0, 'data': null, 'linkDocumentacao': null, 'urlImage': null, 'jaPossuiPacote': false, 'qtdModulos': 0, 'qtdProdutos': 0, 
            'totalViews': 0, 'modulos': [], 'produtos': []
         }
         this.valorRecomendado = 0

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/getPacotes',
            params: {
               id: pacote.id
            }
         }).then(response => {
            this.modalPacote = response.data
            this.modalPacote.valor = parseFloat(this.modalPacote.valor).toFixed(2),
            this.calcularValores(this.modalPacote)
            $('#modalEditarPacote').modal('show')

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		deletarPacote : function (pacote) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Deletar ' + pacote.nome + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deletePacote',
                  params: {
                     id: pacote.id
                  }
               }).then(function () {
                  ref.searchPacotes(0)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Pacote deletado!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      vinculo(item, pacote) {
         item.vinculo = !item.vinculo
         this.valorRecomendado = 0,
         this.calcularValores(pacote)
      },
      calcularValores(pacote) {
         for (var i = 0; i < pacote.modulos.length; i++) {
            if (pacote.modulos[i].vinculo) {
               this.valorRecomendado += pacote.modulos[i].valor;
            }
         }

         for (var h = 0; h < pacote.produtos.length; h++) {
            if (pacote.produtos[h].vinculo) {
               this.valorRecomendado += pacote.produtos[h].valor;
            }
         }
      },
      //cursos
      searchModulo : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchModulo',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
				ref.pesquisa.resultado = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		saveModulo : function () {
         let ref = this;

         if (this.modalModulo.nome == null || this.modalModulo.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um titulo!'
            })

            return
         } else if (this.modalModulo.codigo == null || this.modalModulo.codigo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um código!'
            })

            return
         }

			let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalModulo));

         for (let file of this.$refs.cursoFiles.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Tamanho excedido',
                  text: 'Arquivo excede 10MB'
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveModulo',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(function () {
            ref.searchModulo()
            ref.$toast.fire({
               icon: 'success',
               title: 'Curso salvo!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarCurso').modal('hide')

            this.$refs.cursoFiles.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		adicionarDocumento : function () {
         this.modalModulo = {
            'id': null, 'nome': null, 'codigo': null, 'pontuacao': 0, 'qtdVotos': 0, 'descricao': null, 'nomeArquivo': null, 'valor': '0.00', 'urlImage': null, 
            'media': 5, 'vinculo': false, 'blocos': []
         }

         $('#modalEditarCurso').modal('show')
      },
		editarModulo : function (modulo) {
			this.modalModulo = JSON.parse(JSON.stringify(modulo));
         this.modalModulo.valor = parseFloat(this.modalModulo.valor).toFixed(2),
         $('#modalEditarCurso').modal('show')
      },
		deletarModulo : function (documento) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Deletar ' + documento.nome + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deleteModulo',
                  params: {
                     id: documento.id
                  }
               }).then(function () {
                  ref.searchModulo()

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Curso deletado!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      fecharModulo : function() {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.showCurso = false
         this.modulo = {}
         this.searchModulo()
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      editBloco(bloco) {
         this.bloco.idModulo = this.modalModulo.id
         this.bloco.titulo = bloco.titulo
         this.bloco.id = bloco.id
         $('#modalAddBloco').modal('show')
      },
      addAula() {
         $('#modalAddAula').modal('show')
      },
      addBloco() {
         this.bloco.idModulo = this.modalModulo.id
         this.bloco.titulo = null
         this.bloco.id = null
         $('#modalAddBloco').modal('show')
      },
      saveBloco : function() {
         let ref = this;

         if(this.bloco.titulo == null || this.bloco.titulo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um titulo!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveBloco',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.bloco

         }).then(function () {
            ref.searchModulo()

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalAddBloco').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      deleteBloco : function(bloco) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Deletar ' + bloco.titulo + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deleteBloco',
                  params: {
                     id: bloco.id
                  }
               }).then(function () {
                  ref.fecharModulo()

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Bloco deletado!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      },
      abrirModulo(modulo) {
         this.$router.push('/admModulo/' + modulo.id);
      },
      //script
      searchScripts : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchProduto',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      adicionarScript : function () {
         this.showPassword = false
         this.modalScript = {
            'link': null, 'linkDriver': null, 'valor': '0.00', 'nome': null, 'senhaSvn': null, 'descricao': null, 'loginSvn': null, 'listFotos': [], 
            'vinculo': false, 'id': null, 'linkSvn': null, 'linkDocumentacao': null, 'urlImage': null
         }

         $('#modalEditarScript').modal('show')
      },
      editarScript : function (script) {
         this.modalScript = JSON.parse(JSON.stringify(script));
         this.showPassword = false
         if (this.modalScript.valor != null) {
            this.modalScript.valor = parseFloat(this.modalScript.valor).toFixed(2)
         }

         $('#modalEditarScript').modal('show')
      },
      deleteScript : function (script) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Deletar ' + script.nome + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deleteProduto',
                  params: {
                     id: script.id
                  }
               }).then(function () {
                  ref.searchScripts()

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Script deletado!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      saveScript : function () {
         let ref = this;

         if(this.modalScript.nome == null || this.modalScript.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Insira um titulo!'
            })

            return
         }

			let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalScript));

         if ((this.$refs.imagemFile.files.length + (this.modalScript.listFotos == null ? 0 : this.modalScript.listFotos.length)) > 3) {
            Swal.fire({
               icon: 'error',
               title: "Limite alcançado",
               text: "Limite máximo de anexos excedido (3)."
            });

            this.$refs.imagemFile.value = "";
            return
         }
         
         for (let file of this.$refs.scriptFile.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Tamanho excedido',
                  text: 'Arquivo excede 10MB'
               });
               
               return
            }
         }

         for (let file of this.$refs.imagemFile.files) {
            if (file.size < 10485760) {
               dataForm.append('imagem', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Tamanho excedido',
                  text: 'Arquivo excede 10MB'
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveProduto',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(function () {
            ref.searchScripts()
            ref.$toast.fire({
               icon: 'success',
               title: 'Script salvo!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarScript').modal('hide')

            this.$refs.scriptFile.value = null;
            this.$refs.imagemFile.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      removeImage(index) {
         this.modalScript.listFotos.splice(index, 1);
      }
	},
	mounted() {
      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON' && e.target.parentElement.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });

		this.search()
	}
}

</script>

<style scoped>

.cardCurso {
	width: 100%;
	height: 100%;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageContainer i {
   position: absolute;
   top: 0;
   left: 0;
   margin: 10px; /* Ajuste conforme necessário para espaçamento do ícone em relação às bordas */
}

.borderSelect {
   border: solid 2px green;
}

.borderNotSelect {
   border: solid 2px rgb(94, 94, 94);
}

.cursoVinc {
   width: 100%;
   aspect-ratio: 3/4;
   object-fit: cover;
}

.cards-5 {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

.form-check-label {
   margin-top: 2px;
}

.toggle-password {
   position: absolute;
   top: 73%;
   transform: translateY(-50%);
   right: 16px;
   cursor: pointer;
}

.label-file {
   width: 100%;
   padding: 6px 12px;
}

</style>